
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { Color } from "three";

import { Float, Text } from "@react-three/drei";
export default function Model(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/models/hoodie/hoodie.gltf");
  return (
    <Float position={[0, 0, -0.5]} rotation={[.1, 0, 0]} rotationIntensity={2} floatIntensity={6} speed={1.5}>
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.uploads_files_3448446_Hoodie_MD.geometry}
        rotation={[Math.PI / 2, 0, 0]}
        scale={5}
      >
                <meshBasicMaterial attach={"material"} color={new Color("black")}/>

      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["?"].geometry}
        position={[0.88, 0, 0]}
        rotation={[Math.PI / 2, -0.24, 0]}
      > 
      <meshBasicMaterial attach={"material"} color={new Color("black")}/>
      </mesh>
    </group>
    <Text
      position={[0, -4.3, 0]}
      lineHeight={0.8}
      material-toneMapped={false}
      fontSize={.10}
      font='/bd.woff'

      anchorX="center"
      anchorY="middle">
      MYSTERY HOODIE
    </Text>
    <Text
      position={[0, -4.5, 0]}
      lineHeight={0.8}
      material-toneMapped={false}
      fontSize={.10}
      anchorX="center"
      anchorY="middle">
      PRE ORDER
    </Text>
    </Float>
  );
}

useGLTF.preload("/models/hoodie/hoodie.gltf");