import {
  useNavigate,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import PopUp from "./components/popup";
import Hoodie from "./components/hoodie";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import Logo from "./components/logo";
import QuantitySelector from "./components/QuantitySelector";
// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";
import { createRoot } from "react-dom/client";
import React, { useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import Hat from "./components/Hat";
import Hat1 from "./components/Hat1";
import { OrbitControls, Torus, Backdrop } from "@react-three/drei";
import { AnimatePresence, motion } from "framer-motion";
import { MeshBasicMaterial } from "three";

import About from "./pages/about";
import Newsletter from "./pages/newsletter";
import { Stage } from "@react-three/drei";
import Navbar from "./components/Navbar";
import Menu from "./components/menu";
import { AmbientLight } from "three";
import Gallery from "./pages/gallery";
import Products from "./components/products";

let blackHatImages = {
  prefix: "/images/black_hat/",
  images: ["BC1.jpg", "BC2.jpg", "BC3.jpg", "BC4.jpg", "BC5.jpg", "BC6.jpg"],
};

let yellowHatImages = {
  prefix: "/images/yellow_hat/",
  images: ["YC1.jpg", "YC2.jpg", "YC3.jpg", "YC4.jpg", "YC5.jpg", "YC6.jpg"],
};

export default function App() {
  let navigate = useNavigate();
  const location = useLocation();

  let ref = useRef();

  console.log(window.innerWidth);

  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
  });
  return (
    <div>
      <Navbar nav={navigate} />
      <div
        className="h-screen overflow-y-auto w-screen video child:z-0 absolute top-0"
        style={{
          background: `url('/bg.mp4') no-repeat center center fixed`,
          backgroundSize: "cover",
        }}
      >
        {width >= 768 && (
          <Canvas
            camera={{ position: [0, 0, 6], fov: 75, near: 0.1, far: 1000 }}
            gl={{
              powerPreference: "high-performance",
              alpha: true,
              antialias: true,
              depth: true,
            }}
          >
            <Stage>
              {/* <ambientLight intensity={3} position={[0,0,0]}/> */}

              <Hat1
                size={0.5}
                factor={0}
                nav={navigate}
                position={[-0.5, 1, 0]}
              />
              {/* <Hoodie
              scale={0.5}
              factor={0}
              nav={navigate}
              position={[0, -3, 0]}
            /> */}
              <Logo width={width} />
              <Hat
                size={0.1}
                factor={0.4}
                nav={navigate}
                position={[3, 0, 0]}
              />
              <OrbitControls />
            </Stage>
          </Canvas>
        )}
        {width < 768 && (
          <Canvas
            gl={{
              powerPreference: "high-performance",
              antialias: true,
              stencil: false,
              depth: true,
            }}
          >
            <rectAreaLight intensity={3} position={[0, 0, 0]} />

            <Hat1 size={0.5} factor={0} nav={navigate} position={[0, 2.0, 0]} />

            <Logo width={width} />
            <Hat
              size={0.008}
              factor={0.4}
              nav={navigate}
              position={[0, -2.0, 0.5]}
            />
            <OrbitControls />
            {/* <Hoodie
              scale={0.5}
              factor={0}
              nav={navigate}
              position={[-1, -2, 0]}
            /> */}
          </Canvas>
        )}
      </div>
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route
            element={
              <PopUp
                title={"Amoeba Crochet Hat | Yellow"}
                imageObject={yellowHatImages}
              />
            }
            path="/shop/yellow-hat"
          ></Route>
          <Route
            element={
              <PopUp
                title={"Amoeba Crochet Hat | Black"}
                imageObject={blackHatImages}
              />
            }
            path="/shop/black-hat"
          ></Route>
          <Route element={<Home />} path="/"></Route>
          <Route element={<Menu />} path="/menu"></Route>
          <Route element={<About nav={navigate} />} path="/about"></Route>
          <Route element={<Gallery />} path="/gallery"></Route>
          <Route element={<Products />} path="/shop"></Route>
          <Route element={<Newsletter />} path="/newsletter"></Route>
        </Routes>
      </AnimatePresence>
    </div>
  );
}

function Home() {
  return <></>;
}
