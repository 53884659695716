/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { Text, useGLTF, Float } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { Stage } from "@react-three/drei";
import { Routes, Route, Link } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import * as THREE from "three";
import { motion } from "framer-motion-3d";

export default function Hat(props) {
  const ref = useRef();
  const { nodes, materials } = useGLTF("/models/hat_2/yellow_hat.glb");
  const [hovered, set] = React.useState(false);


  function onClickHandler(event) {
    window.open("https://brentmore.myshopify.com/products/brain-eating-amoeba-bucket-hat", '_blank');

  }

  const variants = {
    hidden: { scale: 0, y: -2, opacity: 0 },
    visible: { scale: 0.05, y: 0, opacity: 1 },
  };

  return (
    <group ref={ref} position={props.position} {...props} dispose={null}>
      <ambientLight intensity={2}/>
      <Float  rotation={[.7, 0, 0]} rotationIntensity={2} floatIntensity={3} speed={1.5}>
      <motion.mesh
        onPointerOver={(e) => set(true)}
        onPointerOut={() => set(false)}
        name="Mesh_0002"
        onClick={(event) => onClickHandler(event)}
        geometry={nodes.Mesh_0002.geometry}
        material={materials["default"]}
        scale={props.size}
        rotation={[Math.PI / 2.3, 0, 0]}
        initial="hidden"
        animate="visible"
        variants={variants}
        transition={{ type: "spring", duration: 2 + props.factor }}
        userData={{ name: "Mesh_0.002" }}
      />
       <Text
      position={[0, -.75, 0]}
      lineHeight={0.8}
      material-toneMapped={false}
      fontSize={.10}
      anchorX="center"
      font='/bd.woff'

      anchorY="middle">
      YELLOW AMOEBA HAT
    </Text>
    <Text
      position={[0, -.9, 0]}
      lineHeight={0.8}
      material-toneMapped={false}
      fontSize={.10}
      anchorX="center"
      anchorY="middle">
      $65
    </Text>
      </Float>

    </group>
  );
}

useGLTF.preload("/models/hat_2/yellow_hat.glb");
