import {motion} from "framer-motion"


const rowVariant = {
  hidden: { opacity: "0% "},
  show: {
    opacity: "100%",
  },
  close: { opacity:"0%" },
};


const transition = {
  type: "spring",
  damping: 30,
  stiffness: 100,
  duration:.6
}

function About(props) {
  return (
    <motion.div  initial="hidden"
    animate="show"
    exit="close"
    transition={transition}
    variants={rowVariant}
    className=" w-screen py-5 z-50 fixed overflow-y-scroll h-screen overflow-hidden bg-white/50 backdrop-blur-md">
      <div className=" h-full child:w-4/5 child:mx-auto">
        <h3 className="font-bold text-3xl">ABOUT</h3>
        <p className="py-6">
          BrentMore is a ever-expanding creative hub not limited to one single
          medium. <br /> <br />  Centered around the premise continuous consumption of
          knowledge through artistic to expression. <br /><br />  BrentMore is love. BrentMore
          is pursuit. BrentMore is growth.
        </p>
      </div>{" "}
    </motion.div>
  );
}

export default About;
