import {  Pagination, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import MyLoader from './MyLoader';

import { motion } from "framer-motion";

import React from 'react';

import sanityClient from "../client.js";
const rowVariant = {
    hidden: { opacity: "0% "},
    show: {
      opacity: "100%",
    },
    close: { opacity:"0%" },
  };
  
  
  const transition = {
    type: "spring",
    damping: 30,
    stiffness: 100,
    duration:.6
  }

function Products(props) {
  const [products, setProducts  ] = React.useState(null);

  React.useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "Product"] {
            images[] {
              asset->
            },
          ...
          }`
      )
      .then((data) => setProducts(data))
      .catch(console.error);
  }, []);
  console.log(products)

  return (
    <motion.div  initial="hidden"
    animate="show"
    exit="close"
    transition={transition}
    variants={rowVariant}
    className=" py-5 z-50  overflow-y-scroll h-screen overflow-x-none bg-black/50 backdrop-blur-md rounded-2xl z-50 mx-auto  w-screen my-5">
      <div className="scrollmenu z-50 overflow-x-none grid md:grid-cols-2 gap-5  h-full rounded-2xl">
        {products && products.map((product, index) =>  { return (
            <div className='w-full h-3/4  px-2 pt-10'>
                <img className=" w-3/4 aspect-square object-cover mx-auto" src={product.images[0].asset.url} alt={`${product.title}`} />
                <p className="bd text-xl flex-py-2 text-white text-center">{product.title}</p>
                {/* buy now button */}
                <div className="flex justify-center">
                    <button className="bg-white  w-3/4 hover:bg-black text-black font-bold py-2 px-4 hover:text-white" onClick={() => window.location.href=product.link}>
                        Buy Now
                    </button>
                </div>
            </div>
            )}
        )}

        <div className="py-12" />
      </div>
    </motion.div>
  );
}

export default Products;
