import Gallery from "./gallery";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import QuantitySelector from "./QuantitySelector";
import { motion } from "framer-motion";

const rowVariant = {
  hidden: { opacity: "0% "},
  show: {
    opacity: "100%",
  },
  close: { opacity:"0%" },
};


const transition = {
  type: "spring",
  damping: 30,
  stiffness: 100,
  duration:.6
}

export default function PopUp(props) {
  return (
    <motion.div
      initial="hidden"
      animate="show"
      exit="close"
      transition={transition}
      variants={rowVariant}
      className=" w-screen z-50 fixed top-0  backdrop-blur-md  h-screen  overflow-y-scroll bg-white  "
    ><div className="bg-white/50  relative  py-4 ">
      <div className="flex mx-auto flex-col ">
      <Link
            to="/"
            className="absolute left-5  mx-auto  rounded-2xl px-4   border-2 text-5xl font-bold"
          >
            X
          </Link>
        <div className="mx-auto py-10 order-1 md:order-first  shadow-black rounded-3xl my-5 md:my-0 outline-black outline-1">
          <Gallery title={props.title} imageObject={props.imageObject} />
          <BuyButton title={props.title} />
        </div>


      </div></div>
    </motion.div>
  );
}

const BuyButton = (props) => (
  <div className="buy-button mx-auto drop-shadow-2xl py-8 w-3/4 md:order-last font-bold text-2xl rounded-3xl">
      <h2 className="text-3xl pb-3 italic">{props.title}</h2>
      <div><p className="text-xl py-3">  ◦Hand-knitted <br/>
    ◦    Organic Cotton Fabric<br/>
    ◦    Breathable for warm weather <br/>
    ◦    Made for multiple hairstyles <br/>
    ◦    Machine washable in cold water<br/>
    ◦    Continue to be and be boundless<br/>

Each order comes with hand-painted edition.<br/>

US shipping $8
Elsewhere $20</p></div>
      <p className="text-3xl font-bold py-3">$65</p>
    <div className="justify-around mb-12 items-center flex flex-row">
      {/* <QuantitySelector /> */}


      {/* if black hat in url  */}
      {
        window.location.href.includes("black")  &&
        <a href="https://brentmore.myshopify.com/cart/43663303835964:1?channel=buy_button" className="text-lg  italic border-2 border-solid border-black  shadow-2xl  px-10 my-auto rounded-full">PURCHASE</a>
      }
      {/* if yellow hat in url  */}
      {
        window.location.href.includes("yellow")  &&
        <a href="https://brentmore.myshopify.com/cart/43663296037180:1?channel=buy_button" className="text-lg  italic border-2 border-solid border-black  shadow-2xl  px-10 my-auto rounded-full">PURCHASE</a>
      }
  
    </div>
  </div>
);
