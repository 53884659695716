import React, { useRef, useState } from "react";
import { useGLTF, Text, Float } from "@react-three/drei";
import { AmbientLight } from "three";
import { Routes, Route, Link } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import * as THREE from "three";
import { useFrame, useResource } from "@react-three/fiber";
import { motion } from "framer-motion-3d";

const variants = {
  hidden: { y: -1, scale: 0 },
  visible: { y: 0, scale: 0.5 },
};

export default function Hat2(props) {
  const ref = useRef();
  const { nodes, materials } = useGLTF("/models/hat_1/gltf2.gltf");
  const [hovered, set] = React.useState(false);


  function onClickHandler(event) {
    window.open("https://brentmore.myshopify.com/products/brain-eating-amoeba-bucket-hat-1", '_blank');

  }



  return (
    <group ref={ref} {...props} dispose={null}>
      <ambientLight />
      <Float position={[0, .5, -1]} rotation={[0, 0, 0]} rotationIntensity={2} floatIntensity={3} speed={1.5}>

      <motion.mesh
        onPointerOver={(e) => set(true)}
        onClick={(event) => onClickHandler(event)}
        onPointerOut={() => set(false)}
        geometry={nodes.Mesh_0.geometry}
        name="Mesh_0"
        material={materials["Mat.1"]}
        rotation={[0, 0.5, 0]}
        receiveShadow={false}
        initial="hidden"
        transition={{ type: "spring", duration: 2 + props.factor }}
        animate="visible"
        variants={variants}
        scale={props.size}
      />
      <Text
      position={[0, -.75, 0]}
      lineHeight={0.8}
      material-toneMapped={false}
      fontSize={.10}
      anchorX="center"
      font='/bd.woff'
      anchorY="middle">
      BLACK AMOEBA HAT
    </Text>
    <Text
      position={[0, -.9, 0]}
      lineHeight={0.8}
      material-toneMapped={false}
      fontSize={.10}
      
      anchorX="center"
      anchorY="middle">
      $65
    </Text>
</Float>


    </group>
  );
}

useGLTF.preload("/models/hat_1/gltf2.gltf");
