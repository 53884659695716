import React from "react";

function QuantitySelector() {
  const [quantity, setQuantity] = React.useState(1);

  return (
    <div>
      <div class="flex flex-row border-2 border-solid border-black rounded-2xl relative">
        <button
          data-action="decrement"
          onClick={() => (quantity > 0 ? setQuantity(quantity - 1) : undefined)}
          class="text-gray-600 hover:text-gray-700  h-full w-10  cursor-pointer outline-none"
        >
          <span class="m-auto text-xl font-thin">−</span>
        </button>
        <p
          type="number"
          class=" justify-center focus:outline-none text-lg text-center w-full  font-semibold text-md hover:text-black focus:text-black  md:text-base cursor-default flex items-center text-gray-700  outline-none"
          name="custom-input-number"
        >
          {quantity}
        </p>
        <button
          data-action="increment"
          class="text-gray-600 hover:text-gray-700 h-full w-10 rounded-r cursor-pointer"
        >
          <span
            class="m-auto text-2xl font-thin"
            onClick={() => {
              setQuantity(quantity + 1);
            }}
          >
            +
          </span>
        </button>
      </div>
    </div>
  );
}

export default QuantitySelector;
