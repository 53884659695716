import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { FaTumblrSquare, FaInstagram } from "react-icons/fa";
import { GrSoundcloud } from "react-icons/gr";
const rowVariant = {
  hidden: { opacity: "0% " },
  show: {
    opacity: "100%",
  },
  close: { opacity: "0%" },
};

const transition = {
  type: "spring",
  damping: 30,
  stiffness: 100,
  duration: 0.2,
};

function Menu(props) {
  return (
    <motion.div
      initial="hidden"
      animate="show"
      exit="close"
      transition={transition}
      variants={rowVariant}
      className={`absolute z-0 h-screen backdrop-blur-md w-screen bg-black/50 top-0`}
    >
      <div className="flex flex-col child:text-xl align-center child:text-white">
        <div className="child:text-center child:text-3xl flex flex-col mt-80">
        <Link to="/" className="z-40">
            home
          </Link>
          <Link to="/gallery" className="z-40">
            Photo gallery
          </Link>
          <Link to="/newsletter" className="z-40">
            Newsletter
          </Link>
          <a href="https://brentmore.myshopify.com/" target="_blank" rel="noreferrer" className="z-40">
            Shop
          </a>
          <Link to="/about" className="z-40">
            About
          </Link>
          <div className="flex flex-row gap-4 mx-auto mt-32">
            <a target="_blank"href="https://brentmore.tumblr.com/">
            <FaTumblrSquare /></a>
            <a target="_blank" href="https://soundcloud.com/kd45">
            <GrSoundcloud /></a>
            <a target="_blank" href="https://www.instagram.com/br3ntmor3/">
            <FaInstagram /></a>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Menu;
