import React, { useRef } from "react";
import { MeshWobbleMaterial, useGLTF } from "@react-three/drei";
import { Stage, Float } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { motion } from "framer-motion-3d";
import {
  EffectComposer,
  Outline,
  Bloom,
  Select,
  Noise,
  Vignette,
  DepthOfField

} from "@react-three/postprocessing";


const variants = {
  hidden: { scale: 0.03, opacity: 0 },
  visible: { scale: .7, opacity: 1 },
};


export default function Logo(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/models/logo/GLBAA.gltf");

  return (

    <group ref={group}  dispose={null}>
      <Float position={[0,0,0]} rotation={[.1, 0, 0]} rotationIntensity={2} floatIntensity={6} speed={1.5}>
        <motion.mesh
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ type: "spring", duration: 2 }}
          geometry={nodes.Extrude1.geometry}
          material={materials["Mat.4"]}
        >
        </motion.mesh>
      </Float>

    </group>

  );


}

useGLTF.preload("/models/logo/GLBAA.gltf");
