import React from "react";
import { motion } from "framer-motion";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
import { FreeMode, Thumbs } from "swiper";

import sanityClient from "../client.js";

const rowVariant = {
  hidden: { opacity: "0% " },
  show: {
    opacity: "100%",
  },
  close: { opacity: "0%" },
};

const transition = {
  type: "spring",
  damping: 30,
  stiffness: 100,
  duration: 0.6,
};

function Gallery() {
  const [thumbsSwiper, setThumbsSwiper] = React.useState(null);

  const [allPostsData, setAllPosts] = React.useState(null);

  React.useEffect(() => {
    sanityClient
      .fetch(`*[_type=="piece"] { piece { asset-> { url } } }`)
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  console.log(allPostsData);

  return (
    <motion.div
      initial="hidden"
      animate="show"
      exit="close"
      transition={transition}
      variants={rowVariant}
      className="w-screen py-5 z-50 fixed  h-screen overflow-hidden bg-black/50 backdrop-blur-md"
    >
      <Swiper
        spaceBetween={10}
        thumbs={{
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        modules={[FreeMode, Thumbs]}
        className="mySwiper2 object-contain"
      >
        {allPostsData &&
          allPostsData.map((post, index) => {
            return (
              post && (
                <SwiperSlide className="object-fit" key={index}>
                  {console.log(post.piece.asset)}
                  <img
                    className="w-full h-3/4"
                    src={post.piece.asset?.url}
                    alt={`PORTFOLIO PIECE`}
                  />
                </SwiperSlide>
              )
            );
          })}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={8}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Thumbs]}
        className="mySwiper my-3"
      >
        {allPostsData &&
          allPostsData.map((post, index) => {
            return (
              post && (
                <SwiperSlide key={index}>
                  <div className="w-full aspect-square h-full object-cover">
                    {post.piece.asset 
                    &&
                    <img c src={post.piece.asset?.url} alt={`PORTFOLIO PIECE`} />

                    }
                  </div>
                  {post.url}
                </SwiperSlide>
              )
            );
          })}
      </Swiper>
    </motion.div>
  );
}

export default Gallery;
