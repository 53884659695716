import {motion} from "framer-motion"
import MailchimpSubscribe from "react-mailchimp-subscribe"
//https://gmail.us8.list-manage.com/subscribe/post?u=83eda9f6684816f59da3c1c5b&id=8918464f4f

const url = "//gmail.us8.list-manage.com/subscribe/post?u=83eda9f6684816f59da3c1c5b&id=8918464f4f";

const SimpleForm = () => <div className="child:flex-col child:flex child:my-3 child:gap-3 mx-auto "><MailchimpSubscribe url={url}/></div>



const rowVariant = {
  hidden: { opacity: "0% "},
  show: {
    opacity: "100%",
  },
  close: { opacity:"0%" },
};


const transition = {
  type: "spring",
  damping: 30,
  stiffness: 100,
  duration:.6
}

function About(props) {
  return (
    <motion.div  initial="hidden"
    animate="show"
    exit="close"
    transition={transition}
    variants={rowVariant}
    className=" w-screen py-5 z-50 fixed overflow-y-scroll h-screen overflow-hidden bg-white/50 backdrop-blur-md">
      <div className=" h-full child:w-4/5 child:mx-auto">
        <h3 className="font-bold text-3xl">subscribe to our newsletter </h3>
        <p className="py-6">
        Sign up with your email address to receive news and
updates.
        </p>
        {/* email sign up */}
 
          <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <div className="flex-row flex items-center rounded-xl">
        <SimpleForm onSubmitted={formData => subscribe(formData)} />
        {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{__html: message}}/>}
        {status === "success" && <div style={{ color: "green" }}>Subscribed !</div>}
      </div>
    )}
  />
      </div>
    </motion.div>
  );
}

export default About;
