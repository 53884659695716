import {  Pagination, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import MyLoader from './MyLoader';

import React from 'react';




function Gallery(props) {
  const [loaded, setLoaded] = React.useState(false);



  return (
    <div className="rounded-2xl mx-auto h-full w-screen my-5">
      <div className="scrollmenu bg-white rounded-2xl">
      <Swiper
      // install Swiper modules
      modules={[ Pagination, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      pagination={{
        dynamicBullets: true,
      }}
      breakpoints={{
        640: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      }}
      navigation
      scrollbar={{ draggable: true }}
      className="w-3/4 min-h-fit"
    >
     {props.imageObject.images.map((image,index) => <SwiperSlide className='my-auto'>
       <div >
        <img onLoad={() => setLoaded(true)} alt={`${props.title} - ${index}`} className="my-auto" src={`${props.imageObject.prefix}${image}`}>{console.log(image)}</img>
        </div>

        </SwiperSlide>
)}
    </Swiper>
      </div>
    </div>
  );
}

export default Gallery;
