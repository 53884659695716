import { useState } from "react";
import Menu from "./menu";

function Navbar(props) {
  const [visible, setVisible] = useState(false);
  function onClickHandler(event) {
    switch (event.target.innerText) {
      case "About":
        props.nav("/about");
        break;
      case "Products":
        props.nav("/products");
        break;
      case "BrentMore":
        props.nav("/");
        break;
    }
  }
  return (
    <>

      <div className="flex child:z-50 flex-row items-center overflow-y-auto text-white justify-around">
        <a className="z-50 left-10 absolute" onClick={() => props.nav('/menu')}>
          Menu
        </a>
        <img
          onClick={() => props.nav('/')}
          src={"/logo.png"}
          className="text-4xl z-30 h-32  text-center py-3 kanit tracking-wide mx-auto"
        />
            </div>

    </>
  );
}

export default Navbar;
